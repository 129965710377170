<template>
  <div class="relative bg-white shadow overflow-y-hidden lg:rounded-md">
    <div class="px-4 py-2 lg:py-3 overflow-x-auto hide-scroll-bar">
      <div class="flex items-center">
        <router-link :to="{ name: 'ControlPanel.Home' }" v-slot="{ navigate, href }" custom>
          <a class="text-sm" :href="href" @click="navigate">
            <i class="fas fa-home disable-icon-replacement-fix"/>
          </a>
        </router-link>

        <span class="text-sm mx-4">
          <i class="fa-solid fa-angle-right"/>
        </span>

        <div class="contents" v-if="visible">
          <template v-for="(route, routeIndex) in allowedRoutes" :key="routeIndex">
            <router-link custom :to="route" v-slot="{ route, href, navigate }" v-if="(routeIndex + 1) < allowedRoutes.length">
              <a class="text-sm font-medium hover:underline whitespace-nowrap self-baseline" :href="href" @click="navigate">
                {{ visible ? route.meta.breadcrumbTitle : route.meta.breadcrumbTitle }}
              </a>
            </router-link>
            <span v-else class="text-sm font-medium whitespace-nowrap">
              {{ visible ? route.meta.breadcrumbTitle : route.meta.breadcrumbTitle }}
            </span>

            <span class="text-sm mx-4" v-if="(routeIndex + 1) < allowedRoutes.length">
              <i class="fa-solid fa-angle-right"/>
            </span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',

  data() {
    return {
      routes: []
    }
  },

  computed: {
    /**
     * Filtered routes based on allowance defined in the route meta
     *
     * @return {Array}
     */
    allowedRoutes() {
      return this.routes.filter((route) => route.meta.breadcrumb === true)
    },

    /**
     * Checks if the breadcrumb is visible
     *
     * @return {Array}
     */
    visible() {
      return this.$store.getters['breadcrumbVisibility']
    },
  },

  mounted() {
    this.routes = this.$route.matched
  },

  watch: {
    '$route.matched': {
      handler(newValue) {
        this.routes = []
        setTimeout(() => this.routes = newValue, 50)
      },
      deep: true,
      immediate: true
    },
    visible() {
      this.routes = []
      setTimeout(() => this.routes = this.$route.matched, 50)
    }
  },
}
</script>

<template>
  <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 bg-black">
    <!-- Sidebar -->
    <div class="w-full px-4 py-5 text-center bg-gradient-to-r from-grey-800 to-grey-900">
      <div class="flex justify-between items-center ">
        <a
          class="w-full bg-center bg-no-repeat bg-contain h-4 block"
          :style="'background-image:url(' + publicPath + '/img/logo-white.svg)'"
        />
        <a
          class="font-semibold antialiased text-white tracking-wide px-4 border-l border-gray-500"
        >
          QTG
        </a>
      </div>
  </div>

  <div class="w-full h-[1px] min-h-[1px] bg-gradient-to-r from-gray-500 to-gray-700"></div>

  <!-- Mainmenu -->
  <div class="overflow-y-auto overflow-x-hidden flex-grow px-2 py-4 p select-none h-full">
    <ul class="flex flex-col space-y-0.5">
      <li>
        <router-link :to="{ name: 'Public.Forms.Overview' }" v-slot="{ route, isActive, href }" custom>
          <a
            :href="href"
            @click.prevent="navigate(route)"
            :aria-current="isActive ? 'page' : undefined"
            :class="[
              isActive ? 'bg-secondary-800 text-white' : 'text-gray-300 hover:bg-secondary-850 hover:text-white',
              'group flex items-center px-1.5 py-1.5 rounded-md'
            ]">
            <span :class="[
              'flex items-center justify-center text-lg min-w-[30px] min-h-[30px] w-[30px] h-[30px] text-white p-2 rounded-md mr-2',
              'bg-gradient-to-tl from-primary to-primary-dark'
            ]">
              <i class="fa-regular fa-file-invoice icon-replacement-fix-margin"/>
            </span>
            Formulieren
          </a>
        </router-link>
      </li>
    </ul>
  </div>

  <div class="w-full h-[1px] bg-gradient-to-r from-gray-500 to-gray-700"></div>

  <div class="w-full bg-gradient-to-r from-grey-800 to-grey-900">
    <ul class="flex flex-col space-y-0.5 p-2">
      <li v-if="hasUser" class="p-2">
        <p v-if="user.name" class="text-white truncate">{{ user.name }}</p>
        <p v-if="user.title" class="text-gray-400 text-sm font-normal">{{ user.title }}</p>
      </li>
      <li>
        <router-link :to="{ name: 'ControlPanel.Settings' }" v-slot="{ route, isActive, href }" custom>
          <a
            :href="href"
            @click.prevent="navigate(route)"
            :aria-current="isActive ? 'page' : undefined"
            :class="[
              isActive ? 'bg-secondary-800 text-white' : 'text-gray-300 hover:bg-secondary-850 hover:text-white',
              'group flex flex-row items-center px-1.5 py-1.5 rounded-md'
            ]">
            <span :class="[
              'flex items-center justify-center text-lg min-w-[30px] min-h-[30px] w-[30px] h-[30px] text-white p-2 rounded-md mr-2',
              'bg-gradient-to-tl from-secondary to-secondary-dark'
            ]">
              <i class="fa-regular fa-sliders-v icon-replacement-fix-margin"/>
            </span>
            <p class="truncate">Instellingen</p>
          </a>
        </router-link>
      </li>
      <li>
        <a
          href="/" @click.prevent="signOut"
          :class="[
            'text-gray-300 hover:bg-secondary-850 hover:text-white',
            'group flex flex-row items-center px-1.5 py-1.5 rounded-md'
          ]">
          <span :class="[
            'flex items-center justify-center text-lg min-w-[30px] min-h-[30px] w-[30px] h-[30px] text-white p-2 rounded-md mr-2',
            'bg-gradient-to-tl from-secondary to-secondary-dark'
          ]">
            <i class="fa-regular fa-sign-out-alt icon-replacement-fix-margin"/>
          </span>
          <p class="truncate">Uitloggen</p>
        </a>
      </li>
    </ul>
  </div>
  </div>

  <!-- pagina -->
  <div class="md:pl-64 flex flex-col flex-1">
    <div class="bg-gray-100 min-h-screen">
      <div class="px-4 lg:px-8 py-2 lg:py-4 bg-secondary lg:bg-transparent">
        <div class="min-h-[32px]">
          <div class="flex justify-between">
            <p class="text-2xl lg:text-3xl">
              Home
            </p>
          </div>
        </div>
      </div>

      <div class="lg:px-8 lg:bg-transparent border-t border-secondary-dark lg:border-t-0">
        <Breadcrumb class="text-gray-500"/>
      </div>

      <div class="lg:px-8 mt-6 lg:mt-10">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div>
            <router-link :to="{
              name: 'Public.Forms.Overview'
            }">
              <div class="bg-white h-30 shadow rounded-md w-full lg:text-center px-4 py-6 lg:px-6 lg:py-8">
                <div class="inline-block lg:block lg:w-full mr-3 lg:mr-0">
                  <i class="fa-regular fa-file-invoice text-xl lg:text-3xl text-gray-700"/>
                </div>
                <div class="inline-block lg:block text-gray-700">
                  <p>Formulieren</p>
                </div>
              </div>
            </router-link>
          </div>
      </div>

      <div class="w-full lg:w-0 lg:h-0 lg:invisible">
        <Tabbar v-model:showMainMenu="showMainMenu" />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
// import Sidebar from '@/components/Sidebar'

export default {
  name: 'Public.Home',

  components: {
    Breadcrumb,
    // Sidebar
  },

  data() {
    return {
      publicPath: window.location.origin
    }
  },

  // created() {
  //   this.redirectDefaultRoute()
  // },

  // updated() {
  //   this.redirectDefaultRoute()
  // },

  methods: {
    /**
     * Redirects to the projects route
     *
     * @return {Void}
     */
    redirectDefaultRoute() {
      if (this.$route.name === 'Forms') {
        this.$router.push({
          name: 'Forms.Overview'
        })
      }
    },

    navigate(route) {
      this.$emit('navigate')
      this.$router.push(route)
    }
  }
}
</script>

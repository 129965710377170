
import moment from "moment"


export default function handleAllRelatedRules(formData, relatedItems){
  let consequences = [];

  let questions, sections, obj
  ({ questions, sections } = createLists(formData))
  if(!questions.length || !sections.length ){
    return []
  }
  for(let i = 0; i < relatedItems.length; i++){
    let item = relatedItems[i]
    if(item.field_id === null){
      obj = findSection(sections, item.section_id)
    }else{
      obj = findQuestion(questions, item.field_id)
    }
    if(obj === false){
      continue
    }
    let result = handleConsequences(obj.consequences, questions)
    if(result !== false){
      consequences.push(result)
    }
  }
  return consequences
}
 export function handleConsequences(consequences, questions){
  //needs to be rewritten if multiple consequences per question/section
  for(let i = 0; i < consequences.length; i++){
    let consequence = consequences[i]
    let result = handleConsequence(consequence, questions)
    //let result = false;
    consequence.invert = !result
    return consequence
  }
  return false;
}
const handler = {
  isEqual : (questionValue, checkValue) => {
    try{
      return parseFloat(questionValue) == parseFloat(checkValue)
    } catch {
      return false
    }
  },
  isGreater : (questionValue, checkValue) => {
    try{
      return parseFloat(questionValue) > parseFloat(checkValue)
    } catch {
      return false
    }
  },
  isSmaller : (questionValue, checkValue) => {
    try{
      return parseFloat(questionValue) < parseFloat(checkValue)
    } catch {
      return false
    }
  },
  isGreaterOrEqual : (questionValue, checkValue) => {
    try{
      return parseFloat(questionValue) >= parseFloat(checkValue)
    } catch {
      return false
    }
  },
  isSmallerOrEqual : (questionValue, checkValue) => {
    try{
      return parseFloat(questionValue) <= parseFloat(checkValue)
    } catch {
      return false
    }
  },
  isIncluded : (questionValue, checkValue) => {
    return questionValue.split(',').includes(checkValue)
  },
  isBefore : (questionValue, checkValue) => {
    return moment(questionValue).unix() < moment(checkValue).unix()
  },
  isAfter : (questionValue, checkValue) => {
    return moment(questionValue).unix() > moment(checkValue).unix()
  },
  isAnswered : (questionValue, checkValue) => {
    if(!questionValue && questionValue !== false && questionValue !== 0){
      return false
    }
    return checkValue
  }
}
function handleConsequence(consequence, questions) {
  const conditionGroups = compileConditionGroups(consequence.conditions)
  for(let i = 0; i < conditionGroups.length; i++){
    if(handleConditiongroup(conditionGroups[i], questions)){
      return true
    }
  }
  return false
}
function handleConditiongroup(conditions, questions){
  for(let i = 0; i < conditions.length; i++){
    if(!handleCondition(conditions[i], questions)){
      return false
    }
  }
  return true
}
function handleCondition(condition, questions){
  const question = findQuestion(questions, condition.field_id)
  if(question === false){
    return false
  }
  if(question.value === null || question.value === undefined){
    return false
  }
  return handler[condition.type.check](question.value, condition.value)
}
function compileConditionGroups(conditions){
  const obj = {}
  conditions.forEach(condition => {
    if(!(condition.parent_id in obj)){
      obj[condition.parent_id] = []
    }
    obj[condition.parent_id].push(condition)
  })
  return Object.values(obj)
}
function findQuestion(questions, id){
  const question = questions.filter(question => question.id == id)
  if(question.length){
    return question[0]
  }
  return false
}
export function findSection(sections, id){
  const section = sections.filter(section => section.id == id)
  if(section.length){
    return section[0]
  }
  return false
}
function createLists(formData){
  var sections = [];
  var questions = [];
  for(let i = 0; i < formData.sections.length; i++){
    sections.push(formData.sections[i])
    for(let j = 0; j < formData.sections[i].questions.length; j++){
      questions.push(formData.sections[i].questions[j])
    }
  }
  return {questions: questions, sections: sections}
}

<template>
  <div class="min-h-[32px]">
    <div class="flex justify-between">
      <p class="text-2xl lg:text-3xl">
        {{ visible ? $route.meta.title : $route.meta.title }}
      </p>
      <div class="flex gap-2">
        <div
          class="bg-white text-gray-700 rounded-md shadow-sm w-[28px] h-[28px] flex items-center justify-center cursor-pointer"
          v-show="showCopyLink"
          @click="copyLink">
          <i class="fas fa-link icon-replacement-fix-relative"/>
        </div>
        <div
          class="bg-white text-gray-700 rounded-md shadow-sm w-[28px] h-[28px] flex items-center justify-center cursor-pointer"
          v-show="showUserProjectFavorite"
          @click="saveProjectAsFavorite">
          <i class="far fa-bookmark icon-replacement-fix-relative" v-show="!isUserProjectFavorite"/>
          <i class="fas fa-bookmark icon-replacement-fix-relative" v-show="isUserProjectFavorite"/>
        </div>
      </div>
    </div>
    <p v-show="$route.meta.description && $route.meta.description.length" class="truncate">
      {{ visible ? $route.meta.description : $route.meta.description }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',

  emits: [
    'click:saveProjectAsFavorite'
  ],

  props: {
    showUserProjectFavorite: {
      type: Boolean,
      default: false,
    },
    showCopyLink: {
      type: Boolean,
      default: false,
    },
    isUserProjectFavorite: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    /**
     * Checks if the pageMeta is visible
     *
     * @return {Array}
     */
    visible() {
      return this.$store.getters['pageMetaVisibility']
    }
  },

  methods: {
    saveProjectAsFavorite() {
      this.$emit('click:saveProjectAsFavorite')
    },

    copyLink() {
      var dummy = document.createElement('input'),
      text = window.location.href;

      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);

      this.$store.dispatch('notification', {
        type: 'info',
        title: 'Link gekopieerd',
        timeout: 2000
      })
    }
  }
}
</script>

<template>
    <TransitionRoot as="template" :show="showModal">
    <Dialog as="div" class="relative z-10" @close="redirectToUrl">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="w-full">
                  <img :src="publicPath + '/img/dansende-banaan-hyves.gif'" alt="" class="m-auto h-28">
                  <!-- <img :src="publicPath + '/img/logo-color.png'" alt="" class="m-auto h-28"> -->
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-xl font-medium leading-6 text-gray-900">Antwoorden verstuurd!</DialogTitle>
                  <div class="mt-2">
                    <p class="text-lg text-gray-500">Bedankt voor uw deelname aan onze prijsvraag. Eind januari maken wij de prijswinnaars bekend.</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button" class="btn-secondary w-full" @click="redirectToUrl">Ga naar onze website</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <div class="px-4 mb-2 lg:mb-0 lg:px-0 py-2 lg:py-4 bg-primary lg:bg-transparent">
    <div class="min-h-[32px]">
      <div class="justify-between lg:hidden w-full">
          <a
            class="w-full items-center align-center bg-center mt-3 bg-no-repeat bg-contain h-5 block"
            :style="'background-image:url(' + publicPath + '/img/logo-white.svg)'"
          />
      </div>
    </div>
  </div>
 <PageTitle class="text-black px-4 mb-2 text-center lg:px-0 lg:text-current"/>
  <form @submit.prevent="submitForm" novalidate v-show="!isFetchingInitialData">
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5 space-y-6 lg:space-y-0">
      <div :class="[
        'space-y-6 px-4 lg:px-0',
        { 'lg:col-span-9': !isFinalized },
        { 'lg:col-span-12': isFinalized }
      ]">
        <router-view
          v-if="!isFetchingInitialData"
          :formId="formId"
          :formTitle="formTitle"
          :formData="form"
          :sectionData="currentSection"
          :submissionData="submissionData"
          :hasPreviousBtn="!isFirstSection"
          :hasNextBtn="!isLastSection"
          :hasFinishBtn="isLastSection"
          v-model:waitForSaving="waitForSaving"
          @update:submissionData="updateSubmission"
          @inputChanged="executeConditions"
          @previous="goToPreviousSection"
          @next="goToNextSection"
          @finalize="goToFinalize"
        />
      </div>

      <aside class="pb-6 px-4 lg:pb-0 lg:px-0 lg:col-span-3" v-if="!isFinalized">
        <div class="space-y-4 lg:sticky lg:top-5 m">
          <div class="bg-white shadow rounded-md divide-y">
            <div class="px-4 py-3 space-y-3">
              <button
                type="submit"
                :class="['w-full btn-secondary font-medium', { 'opacity-60 pointer-events-none': waitForSaving }]"
                :disabled="waitForSaving">
                <img :src="publicPath + '/img/spinner-white.svg'" width="24" v-show="waitForSaving"/>
                <span v-show="!waitForSaving">Versturen*</span>
              </button>
            </div>

            <div class="px-4 py-3">
              <p class="text-gray-600 text-sm text-center cursor-pointer hover:text-red-500 transition-colors" @click="removeSubmission">
                * met het verzenden van dit formulier geef ik Geonius toestemming voor het verwerken van de verstrekte gegevens. Deze gegevens worden gebruikt om de winnaars van de prijsvraag te kunnen contacteren. Daarnaast zullen wij u na InfraTech eenmalig informeren over de dienstverlening van Geonius en wordt u aangemeld voor onze nieuwsbrief waarvoor u zich te allen tijde kunt uitschrijven.*
              </p>
            </div>
            <!-- <div class="px-4 py-3">
              <p class="text-red-400 text-sm text-center cursor-pointer hover:text-red-500 transition-colors" @click="removeSubmission">
                Verwijder of annuleer deze formulier inzending
              </p>
            </div> -->
          </div>

          <!-- <nav :class="[
            'bg-white shadow rounded-md overflow-x-hidden overflow-y-auto max-h-[400px]',
            { 'opacity-60 pointer-events-none': waitForSaving }
            ]">
            <ol role="list" class="divide-y">
              <li v-for="section in sections" :key="section.title">
                <router-link
                  custom
                  :to="{ name: 'Public.Forms.Single.Section', params: { id: $route.params.id, section: section.id }, query: $route.query }"
                  v-slot="{ href, navigate }"
                >
                  <a class="flex items-start px-3 py-3" v-if="parseInt(section.id) === parseInt($route.params.section)" :href="href" @click="navigate" aria-current="section">
                    <span class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center mt-0.5" aria-hidden="true">
                      <span class="absolute h-4 w-4 rounded-full bg-secondary bg-opacity-50" />
                      <span class="relative block w-2 h-2 bg-secondary rounded-full" />
                    </span>
                    <span class="ml-2 min-w-0 flex flex-col">
                      <span class="font-medium truncate text-secondary">{{ section.title }}</span>
                    </span>
                  </a>
                  <a class="block group px-3 py-3" v-else :href="href" @click="navigate">
                    <div class="flex items-start">
                      <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center mt-0.5" aria-hidden="true">
                        <div class="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                      </div>
                      <span class="ml-2 min-w-0 flex flex-col">
                        <span class="font-medium truncate text-gray-500">{{ section.title }}</span>
                      </span>
                    </div>
                  </a>
                </router-link>
              </li>

              <li class="bg-gray-50">
                <router-link
                  custom
                  :to="{ name: 'Public.Forms.Single.Finalize', params: { id: $route.params.id }, query: $route.query }"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a class="flex items-start px-4 py-3" v-if="isExactActive" :href="href" @click="navigate" aria-current="section">
                    <span class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center mt-0.5" aria-hidden="true">
                      <span class="absolute h-4 w-4 rounded-full bg-secondary bg-opacity-50" />
                      <span class="relative block w-2 h-2 bg-secondary rounded-full" />
                    </span>
                    <span class="ml-2 min-w-0 flex flex-col">
                      <span class="font-medium truncate text-secondary">Controleren & versturen</span>
                    </span>
                  </a>
                  <a class="block group px-4 py-3" v-else :href="href" @click="navigate">
                    <div class="flex items-start">
                      <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center mt-0.5" aria-hidden="true">
                        <div class="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                      </div>
                      <span class="ml-2 min-w-0 flex flex-col">
                        <span class="font-medium truncate text-gray-500">Controleren & versturen</span>
                      </span>
                    </div>
                  </a>
                </router-link>
              </li>
            </ol>
          </nav> -->
        </div>
      </aside>
    </div>
  </form>
  <a
    class="w-full mt-6 bg-center bg-no-repeat bg-contain h-5 block"
    :style="'background-image:url(' + publicPath + '/img/logo-black.svg)'"
  />
</template>

<script>
import PageTitle from '@/components/pages/Title'
import moment from 'moment'
import handleAllRelatedRules from '@/store/helpers/surveys/ruleHandler'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  name: 'Public.Forms.Single',

  inject: ['axios'],

  components: {
    PageTitle, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot
  },

  data() {
    return {
      isFetchingInitialData: true,
      formData: {},
      projectnr: null,
      submissionData: {},
      collaborators: null,
      answersFilled: false,
      waitForSaving: false,
      publicPath: window.location.origin,
      formId: null,
      showModal: false
    }
  },

  computed: {
    // formId() {
    //   return parseInt(this.$route.params.id)
    // },

    formTitle() {
      return this.form.title ?? ''
    },

    form() {
      return this.formData ?? {}
    },

    hasSubmission() {
      return this.$route.query.submission && !isNaN(this.$route.query.submission) ? true : false
    },

    submissionId() {
      return this.hasSubmission ? parseInt(this.$route.query.submission) : null
    },

    isProjectForm() {
      return this.$route.query.project && !isNaN(this.$route.query.project) ? true : false
    },

    projectId() {
      return this.isProjectForm ? parseInt(this.$route.query.project) : null
    },

    sections() {
      let sections = this.form.sections
      if (sections && sections.length) {
        sections = sections.filter((section) => {
          let isVisible = section.visible == 1
          let hasVisibleQuestions = !!section.questions.filter(question => question.visible == 1).length
          return isVisible && hasVisibleQuestions
        })
        return sections.sort(function (a,b) {
          if ( a.order < b.order ){
            return -1
          }
          if ( a.order > b.order ){
            return 1
          }
          return 0
        }) ?? []
      }

      return []
    },

    currentSection() {
      if (this.$route.params.section && !isNaN(this.$route.params.section)) {
        const currentSection = this.sections.filter((section) => section.id === parseInt(this.$route.params.section))
        if (currentSection.length) {
          return currentSection[0]
        }
      }
      return {}
    },

    previousSection() {
      if (this.$route.params.section && !isNaN(this.$route.params.section)) {
        const currentSectionIndex = this.sections.findIndex(section => section.id == this.currentSection.id)
        const previousSection = this.sections[currentSectionIndex - 1] ? this.sections[currentSectionIndex - 1] : {}
        if (Object.keys(previousSection).length) {
          return previousSection
        }
      }
      return {}
    },

    nextSection() {
      if (this.$route.params.section && !isNaN(this.$route.params.section)) {
        const currentSectionIndex = this.sections.findIndex(section => section.id == this.currentSection.id)
        const nextSection = this.sections[currentSectionIndex + 1] ? this.sections[currentSectionIndex + 1] : {}
        if (Object.keys(nextSection).length) {
          return nextSection
        }
      }
      return {}
    },

    isFirstSection() {
      let currentSectionIndex = this.sections.findIndex(section => section.id === this.currentSection.id)
      return currentSectionIndex === 0
    },

    isLastSection() {
      let currentSectionIndex = this.sections.findIndex(section => section.id === this.currentSection.id)
      return currentSectionIndex >= (this.sections.length - 1)
    },

    isFinalizing() {
      return this.$route.name === 'Public.Forms.Single.Finalize'
    },

    isFinalized() {
      return this.submissionData.finished && !this.$store.getters['Public/user/role/can']('survey.submission:edit')
    },

    userCanSubmitForm() {
      if (!Object.keys(this.formData).length) return
      const roleSubmitActions = this.formData.role_actions.filter(roleAction => roleAction.action === 'submit')
      if (!roleSubmitActions || !roleSubmitActions.length) {
        return true
      }

      // Map user roles to only role codes
      const userRoles = this.$store.getters['Public/user/role/all'].map(role => role.code)
      // Checks if roleSubmitActions contains the current user's role
      return roleSubmitActions.some(roleAction => userRoles.includes(roleAction.role_id))
    },

    formNotSubmitted() {
      if(this.submissionData.finished){
        return false
      } else {
        return true
      }
    },
  },

  created() {
    this.init()
  },

  methods: {
    /**
     * Initializes this page
     *
     * @return {Void}
     */
    async init() {
      const formData = await this.getData(`v1/public/surveys/group/${this.$route.params.name}`, {
        portal: 'QTG'
      })

      if (Object.keys(formData).length) {
        this.formData = formData
        if(formData.id !== null && formData.id !== undefined){
          this.formId = formData.id
        }
      }

      await this.redirectToRelevantSection()
      this.isFetchingInitialData = false
      this.isLoading = false
    },

    redirectToUrl() {
      window.location.replace("http://www.geonius.nl");
    },

    /**
     * Fetches data of a single entity
     *
     * @param {String} endpointURI
     * @param {Object} customParams
     * @return {Promise}
     */
    async getData(endpointURI, customParams = {}) {
      let params = Object.assign({
        // default params here...
      }, customParams)

      try {
        var request = await this.axios.get(endpointURI, { params: params })
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'Public.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'Public.Unauthorized'
          })
        } else {
          return Promise.reject(error)
        }
      }

      return Promise.resolve(request.data)
    },


    /**
     * Patches an submission
     *
     * @param {String} endpointURI
     * @param {Object} customBody
     * @return {Promise}
     */
    async patchSubmission(endpointURI, customBody = {}) {
      let body = Object.assign({
        //
      }, customBody)

      try {
        await this.axios.patch(endpointURI, body)
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'Public.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'Public.Unauthorized'
          })
        } else {
          return Promise.reject(error.response.data)
        }
      }

      return Promise.resolve(true)
    },

    /**
     * Validates all form elements and continues to update the submission with
     * a finished label.
     *
     * @param {SubmitEvent} e
     * @return {Void}
     */
    async submitForm(e) {
      if (!e.target.checkValidity()) {
        this.renderValidationErrors(e.target.elements)
        return
      }

      const patchSubmission = await this.patchSubmission(`v1/public/surveys/submissions/${this.submissionId}`, {
        closed: true
      })
      if (patchSubmission === true) {
        this.showModal = true
      }
    },
    addAnswers(answers, question, row = null){
      if(question.questions.length){

        for(var i = 0; i < question.questions.length; i++){
          this.addAnswers(answers, question.questions[i], row)
        }
      }else{
        answers.push({
          question: question.id,
          answer: question.value,
          row: row
        })

      }
      return answers
    },
    /**
     * Goes through all form elements (browser) and connects the browser
     * validations with the questions array by inserting the validation message
     * in to the question errors
     *
     * @return {Void}
     */
    renderValidationErrors(fields) {
      for (let i = 0; i < fields.length; i++) {
        const element = fields[i]
        if (!element.checkValidity()) {
          for (let i = 0; i < this.formData.sections.length; i++) {
            const section = this.formData.sections[i]
            for (let q = 0; q < section.questions.length; q++) {
              const question = section.questions[q]
              if (question.id == element.name) {
                this.formData.sections[i].questions[q].errors = [element.validationMessage]
              }
            }
          }
        }
      }
    },

    updateSubmission(newSubmissionData) {
      this.submissionData = newSubmissionData
      if (!this.$route.query.submission) {
        this.$router.replace({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            scrollBehavior: 'skip'
          },
          query: {
            ...this.$route.query,
            submission: newSubmissionData.id
          }
        })
      }
    },

    fillAnswers() {
      if (!this.answersFilled) {
        for (let a = 0; a < this.form.sections.length; a++) {
          const section = this.form.sections[a]

          section.questions = this.fillQuestionAnswers(section.questions)
        }
        this.answersFilled = true
      }
    },

    fillQuestionAnswers(questions) {
      for (let b = 0; b < questions.length; b++) {
        const question = questions[b]

        if (question.type.name === 'repeater') {
          question.questions = this.fillQuestionAnswers(question.questions)
          question.value = this.getRepeaterAnswersById(question.id)
          continue
        }

        let relatedAnswer = this.submissionData.answers.filter((answer) => answer.field_id === question.id)
        if (relatedAnswer && relatedAnswer.length) {
          relatedAnswer = relatedAnswer[0]
          let relatedAnswerValue = relatedAnswer[question.type.field]
          question.answer = relatedAnswer
          if (relatedAnswerValue === null) {
            continue
          }
          switch (question.type.field) {
            case 'numeric_value':
              question.value = parseFloat(relatedAnswerValue)
              break;
            case 'datetime_value':
              question.value = moment(relatedAnswerValue).format(moment.HTML5_FMT.DATETIME_LOCAL)
              break;

            default:
              question.value = relatedAnswerValue.toString()
              break;
          }
          if (question.type.name === 'upload') {
            question.value = JSON.parse(question.value)
          }
          if (question.type.name === 'time') {
            question.value = moment(question.value, [moment.ISO_8601, moment.HTML5_FMT.TIME]).format(moment.HTML5_FMT.TIME)
          }
        } else {
          question.value = null
        }
      }
      return questions
    },

    getRepeaterAnswersById(parentQuestionId) {
      if (!this.hasSubmission) return []
      return this.submissionData.answers.filter((answer) => {
        return answer.field.parent_id === parentQuestionId
      })
    },

    executeAllConditions() {
      for (let a = 0; a < this.form.sections.length; a++) {
        const section = this.form.sections[a];
        for (let b = 0; b < section.questions.length; b++) {
          const question = section.questions[b]
          if (question.touched_consequences && question.touched_consequences.length) {
            this.executeConditions(question.touched_consequences)
          }
        }
      }
    },

    executeConditions(related) {
      if (related === undefined || related === null || !related.length ) {
        return
      }
      let consequences = handleAllRelatedRules(this.form, related)
      for(let i = 0; i < consequences.length; i++) {
        const consequence = consequences[i]
        if(consequence.section_id === null){
          let result = this.findQuestionIndex(consequence.field_id)
          if(result === false){
            return
          }
          this.form.sections[result.section].questions[result.question].visible = this.invertBool(consequence.show, consequence.invert)
          this.form.sections[result.section].questions[result.question].required = this.invertBool(consequence.required, consequence.invert)
        }else{
          let result = this.findSectionIndex(consequence.section_id)
          if(result === false){
            return
          }
          this.form.sections[result].visible = this.invertBool(consequence.show, consequence.invert)
        }
      }
    },

    executeConsequences(consequences) {
      if (consequences === undefined || consequences === null) {
        return
      }
      for (let a = 0; a < consequences.length; a++) {
        const consequence = consequences[a]
        if(consequence.field_id === null || consequence.field_id === undefined) {
          //section logic
          for(let d = 0; d < this.form.sections.length; d++){
            let targetSection = this.form.sections[d]
            if(targetSection.id == consequence.section_id){
              targetSection.visible = consequence.show
              break
            }
          }

        }else{
          for (let b = 0; b < this.form.sections.length; b++) {
            const section = this.form.sections[b]
            for (let c = 0; c < section.questions.length; c++) {
              let targetQuestion = section.questions[c]

              if (targetQuestion.id !== consequence.field_id) continue
              if (consequence.show !== null) {
                targetQuestion.visible = consequence.show
              }
              if (consequence.required !== null) {
                targetQuestion.required = consequence.required
              }
            }
          }
        }
      }
    },
    invertBool(bool, invert = true){
      if(!invert){
        return !!bool
      }
      return !bool
    },
    findQuestionIndex(id){
      for (let i = 0; i < this.form.sections.length; i++) {
          const section = this.form.sections[i]
          const index = section.questions.map(e => e.id).indexOf(id);
          if (index != -1) {
            return {section: i, question: index}
          }
      }
      return false
    },
    findSectionIndex(id){
      const index = this.form.sections.map(e => e.id).indexOf(id)
      if(index != -1) {
        return index
      }
      return false
    },
    executeBackwardConsequences(consequences) {
      if (consequences === undefined || consequences === null) {
        return
      }

      for (let a = 0; a < consequences.length; a++) {
        const consequence = consequences[a]
                if(consequence.field_id === null || consequence.field_id === undefined) {
          //section logic
          for(let d = 0; d < this.form.sections.length; d++){
            let targetSection = this.form.sections[d]
            if(targetSection.id == consequence.section_id){
              targetSection.visible = !consequence.show
              break
            }
          }
        }else{
          for (let b = 0; b < this.form.sections.length; b++) {
            const section = this.form.sections[b]
            for (let c = 0; c < section.questions.length; c++) {
              let targetQuestion = section.questions[c]

              if (targetQuestion.id !== consequence.field_id) continue
              if (consequence.show !== null) {
                targetQuestion.visible = !consequence.show
              }
              if (consequence.required !== null) {
                targetQuestion.required = !consequence.required
              }
            }
          }
        }
      }
    },

    async redirectToRelevantSection() {
      if (this.isFinalized && !this.$store.getters['Public/user/role/can']('survey.submission:edit')) {
        await this.$router.replace({
          name: 'Public.Forms.Single.View',
          params: {
            id: this.$route.params.id
          },
          query: this.$route.query
        }).catch(() => {})
        return Promise.resolve(true)
      }

      if (this.$route.name === 'Public.Forms.Single.Finalize') {
        return Promise.resolve(true)
      }

      if (this.$route.params.section && !isNaN(this.$route.params.section)) {
        var relevantSection = this.sections.filter((section) => section.id === parseInt(this.$route.params.section))
      }
      if (!this.$route.params.section || isNaN(this.$route.params.section)
        || !relevantSection.length || this.$route.name === 'Public.Forms.Single') {
        await this.$router.replace({
          name: 'Public.Forms.Single.Section',
          params: {
            id: this.$route.params.id,
            section: this.sections[0].id
          },
          query: this.$route.query
        })
        return Promise.resolve(true)
      }

      return Promise.resolve(true)
    },

    goToPreviousSection() {
      if (!this.isFirstSection) {
        return this.$router.push({
          name: 'Public.Forms.Single.Section',
          params: {
            id: this.$route.params.id,
            section: this.previousSection.id
          },
          query: this.$route.query
        })
      }
    },

    goToNextSection() {
      if (!this.isLastSection && !!Object.keys(this.nextSection).length) {
        return this.$router.push({
          name: 'Public.Forms.Single.Section',
          params: {
            id: this.$route.params.id,
            section: this.nextSection.id
          },
          query: this.$route.query
        })
      }
    },

    goToFinalize() {
      return this.$router.push({
        name: 'Public.Forms.Single.Finalize',
        params: {
          id: this.$route.params.id
        },
        query: this.$route.query
      })
    },

    moment(constructor) {
      return moment(constructor)
    },

    removeSubmission() {
      this.$store.dispatch('showModal', {
        title: 'Inzending verwijderen',
        description: 'Weet je zeker dat je de inzending wilt verwijderen?',
        component: 'DeleteConfirmation',
        onSubmit: () => this.deleteSubmission(),
      })
    },

    async deleteSubmission() {
      if(this.submissionId) {
        try {
          await this.axios.delete(`v1/surveys/submissions/${this.submissionId}`)
        } catch (error) {
          if (error.response.status === 404) {
            return this.$router.push({
              name: 'Public.NotFound'
            })
          } else if (error.response.status === 403) {
            return this.$router.push({
              name: 'Public.Unauthorized'
            })
          } else {
            return Promise.reject(error.response.data)
          }
        }

        this.$store.dispatch('closeModal')
        this.$store.dispatch('notification', {
          type: 'success',
          title: 'Verwijderd',
          description: 'De inzending is verwijderd',
          timeout: 5000
        })
        this.$router.push({
          name: 'Public.Forms.Overview'
        })

        return Promise.resolve(true)

      } else {
        this.$store.dispatch('closeModal')
        this.$store.dispatch('notification', {
          type: 'success',
          title: 'Geannuleerd',
          description: 'Formulier inzenden is geannuleerd',
          timeout: 5000
        })
        this.$router.push({
          name: 'Public.Forms.Overview'
        })
      }
    },
  },

  async beforeRouteUpdate(to, from) {
    if (from.name === 'Public.Forms.Single.View'
      && to.name === 'Public.Forms.Single.Section' && this.isFinalized
      && !this.$store.getters['Public/user/role/can']('survey.submission:edit')) {
      await this.$router.push({
        name: 'Public.Forms.Single.View',
        params: {
          id: this.$route.params.id
        },
        query: this.$route.query
      })
    } else if (to.name === 'Public.Forms.Single') {
      await this.$router.push({
        name: 'Public.Forms.Single.Section',
        params: {
          id: this.$route.params.id,
          section: this.sections[0].id
        },
        query: this.$route.query
      })
    }
  }
}
</script>

<template>
  <!-- pagina -->
  <div class="flex flex-col flex-1 bg-gray-100">
    <div class="min-h-screen lg:mx-14 mb-4">
      <div>
        <router-view />
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Public.Forms.Overview',

  components: {
    // Breadcrumb,
    // Sidebar
  },

  data() {
    return {
      publicPath: window.location.origin
    }
  },

  // created() {
  //   this.redirectDefaultRoute()
  // },

  // updated() {
  //   this.redirectDefaultRoute()
  // },

  methods: {
    navigate(route) {
      this.$emit('navigate')
      this.$router.push(route)
    }
  }
}
</script>
